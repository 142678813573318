@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base{
  @font-face {
    font-family: 'WorkSans';
    src: local('WorkSans'), url(./assets/font/WorkSans-Regular.ttf) format('ttf');
  }
  html{
    font-family: 'WorkSans';
	
  }

}
#dashboard-content {
	position: absolute;
	min-height: 100%;
	width: calc(100% - 16rem);
	left: 16rem;
	background-color: #F4F4F4;
  
  }
.loader {
	position: fixed;
	top: 0;
	bottom: 0;
	right: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.4);
	z-index: 9999;
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
}

.loader img {
	max-width: 50px;
}
.quill {
    background-color: #fff!important;
    border-radius: 8px;
    border: 1px rgb(209, 213, 219) solid !important;
}
.ql-toolbar{
    border: none !important;
}
.ql-container{
    border: none !important;
}
.ql-container {
    min-height: 10rem;
}

.overlay{
	background: rgba(0, 0, 0, 0.3);
	z-index: 1000;
}